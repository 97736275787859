import React, {useState, useEffect} from 'react';
import { CryptoCurrencyMarket } from "react-ts-tradingview-widgets";
import { TickerTape } from "react-ts-tradingview-widgets";

const Market = () => {

    let theme = localStorage.getItem('PERFECT VALUE-Theme');
    if (theme === 'dark-mode') {
        theme = 'dark'
     }else{
      theme = 'light'
     }
    const [currentTheme, setCurrentTheme] = useState(theme);

    useEffect(()=>{
        document.title = 'MARKETS';
        window.addEventListener('themeChanged', function (e) {
             let theme = localStorage.getItem('PERFECT VALUE-Theme');
             if (theme !== null) {
                 if (theme === 'dark-mode') {
                    setCurrentTheme('dark')
                 }else{
                    setCurrentTheme('light') 
                 }
                
             }             
        }, false);
    })
  return (
        <div id="page-content">
         <div className="">
            <div className="card pt-2 px-0 rounded-2 shadow-sm mx-0">
                <div className=" mt-0 mb-0">
                     <TickerTape colorTheme={currentTheme}></TickerTape>
                    <CryptoCurrencyMarket colorTheme={currentTheme} width="100%" height={400}></CryptoCurrencyMarket>                  
                </div>
            </div>
        </div>
    </div>
  )
}

export default Market